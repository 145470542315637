.vehicle-detail {
  padding: 20px;
  color: #333;
  font-family: 'Abel', sans-serif;
}

.vehicle-info {
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.vehicle-images {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.image-container {
  width: 100%;
  height: 400px; /* Fixed height to prevent content shift */
  display: flex;
  align-items: center;
  justify-content: center;
}

.vehicle-images img {
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.vehicle-images img.fade-in {
  opacity: 1;
}

.vehicle-title {
  font-size: 28px;
  margin: 20px 0;
  text-align: center;
}

.vehicle-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vehicle-details {
  margin: 20px 0;
}

.vehicle-details table {
  width: 100%;
  border-collapse: collapse;
}

.vehicle-details .label {
  font-weight: bold;
  width: 30%;
  padding: 10px;
  background-color: #146C94;
  color: #fff;
}

.vehicle-details .value {
  padding: 10px;
  background-color: #f9f9f9;
}

.pricing-info {
  margin: 20px 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #146C94;
  background-color: #e0f7fa;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pricing-info p {
  margin: 5px 0;
}

.description {
  margin: 20px 0;
}

.finance-info {
  margin: 20px 0;
  background-color: #146C94;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  max-height: 90%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.modal-content {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto; /* Ensure image is centered vertically and horizontally */
}

.swiper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #146C94;
  width: 44px;
  height: 44px;
  margin: 10px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}

/* Fade-in animation */
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards ease-in-out;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #146C94;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for smaller screens */
@media (max-width: 480px) {
  .vehicle-images .image-container {
    height: 200px; /* Adjusted height for smaller screens */
  }

  .vehicle-title {
    font-size: 20px;
  }

  .vehicle-details .label,
  .vehicle-details .value {
    font-size: 16px;
    padding: 6px;
  }

  .description {
    font-size: 16px;
  }

  .pricing-info {
    font-size: 20px;
    padding: 10px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 16px;
  }
}
