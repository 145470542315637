.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .error-content {
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
  }
  
  .error-icon {
    font-size: 4rem;
    color: #ff6347;
  }
  
  .error-title {
    font-size: 2rem;
    color: #333;
  }
  
  .error-message {
    font-size: 1.2rem;
    color: #666;
  }
  
  .error-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #146C94;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .error-button:hover {
    background-color: #19A7CE;
  }
  