.faq-item {
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.faq-section {
    font-family: 'Abel', sans-serif;
    padding: 20px;
    color: #333;
    background-color: #FFFFFF;
}

.faq-title {
    font-family: 'Antonio', sans-serif;
    color: #333;
    text-align: center;
    padding: 10px;
    font-size: 2rem;
}

@media (min-width: 1001px) {
    .faq-section {
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 50px;
    }
}