.privacy-item {
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.privacy-section {
    font-family: 'Abel', sans-serif;
    padding: 20px;
    color: #333;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    min-height: 25vh;
}

.privacy-title {
    font-family: 'Antonio', sans-serif;
    color: #333;
    text-align: center;
    padding: 10px;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.logo-banner {
    text-align: center;
    margin-top: 30px;
}

.logo-svg {
    width: 150px;
    height: auto;
}

.privacy-list ul {
    margin-left: 20px;
}

@media (min-width: 1001px) {
    .privacy-section {
        padding: 50px;
    }
}
