.footer {
  background-color: #146C94;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  font-family: 'Abel', sans-serif;
  padding-bottom: 6rem; /* Increased padding-bottom to ensure space for social icons */
}

.footer h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.footer p {
  font-size: 1.5em;
}

.contact-row {
  display: flex;
  justify-content: center;
}

.contact-column {
  flex: 1;
  max-width: 600px;
  padding: 0 10px;
  text-align: left;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.contact-column.reverse {
  text-align: right;
}

.contact-column h3 {
  font-size: 2em;
}

.contact-column p {
  margin: 5px 0;
}

.social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Antonio', sans-serif;
  padding-bottom: 2rem; /* Added padding-bottom to ensure no content is cut off */
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.social-icons img {
  width: 42px;
  height: 42px;
  margin-bottom: 5px;
}

.social-icons a:hover {
  transform: scale(1.1);
}

@media (max-width: 400px) {
  .footer h2 {
    font-size: 2em;
  }

  .footer p {
    font-size: 1.2em;
  }

  .contact-column h3 {
    font-size: 1.5em;
  }

  .contact-column p {
    font-size: 1em;
  }
}

@media (max-width: 650px) {
  .contact-row {
    flex-direction: column;
  }

  .contact-column,
  .contact-column.reverse {
    text-align: center;
    margin-bottom: 20px;
  }

  .social-icons {
    flex-wrap: wrap;
    justify-content: center;
  }
}
