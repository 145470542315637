.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: 'Abel', sans-serif;
  }
  
  .login-container {
    background: #ffffff;
    padding: 16px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
  }
  
  .login-container h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .login-container input {
    /* padding: 0.5rem; */
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.75rem;
    width: 100%;
  }
  
  .login-container button {
    padding: 0.75rem;
    background-color: #19A7CE;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-container button:hover {
    background-color: #146C94;
  }
  
  .login-container p {
    color: red;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
  