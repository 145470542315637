.gallery-section {
  background-color: white;
  padding: 15px;
  justify-content: center;
  max-width: 1400px; /* Increase max-width to allow space for 7 items */
  margin: 0 auto;
}

.gallery-item-link {
  text-decoration: none;
  color: inherit;
}

.gallery-item {
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  padding: 8px;
}

.car-image {
  width: 100%;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.car-title {
  margin-top: 8px;
  font-size: 0.75rem;
  color: #333;
  font-family: 'Antonio', sans-serif;
}

.gallery-item:hover {
  transform: scale(1.05);
}

.no-search-results {
  font-family: 'Antonio', sans-serif;
  font-size: 0.75em;
  text-align: center;
  margin-top: 20px;
  color: #333;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s;
}

.swiper-slide-active {
  transform: scale(1.2);
}

.swiper-pagination {
  bottom: 10px !important;
}

.scroll-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.arrow {
  font-size: 2rem;
  color: #146C94;
  margin: 0 10px;
}

.scroll-text {
  font-size: 12px;
  color: #146C94;
  font-family: 'Antonio', sans-serif;
}

@media (min-width: 1000px) {
  .scroll-indicator {
    display: none;
  }
}

.swiper-container {
  width: 100%;
  max-width: 1400px; /* Adjust to allow for all 7 slides to be shown */
  margin: 0 auto;
}
