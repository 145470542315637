a {
  text-decoration: none;
}

.vehicles-page {
  padding: 15px;
  padding-top: 10px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-family: 'abel', sans-serif;
  min-height: 100vh;
}

.vehicles-container-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.search-section-new {
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-bottom: 5px; /* Reduced margin */
}

.search-bar {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  transition: border-color 0.3s ease;
}

.search-bar:focus {
  border-color: #146C94;
  outline: none;
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 4px 4px 0;
  background-color: #146C94;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #104f6f;
}

.region-buttons-new {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Reduced margin */
  margin-top: 2px; /* Minimal margin above the buttons */
  flex-wrap: nowrap; /* Keep buttons on the same line for longer */
  gap: 5px; /* Reduced gap between buttons */
}

.region-button-new {
  padding: 10px 15px; /* Slightly reduced padding */
  font-size: 14px; /* Slightly smaller font to fit more buttons on the same line */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #146C94;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
}

.region-button-new.active, .region-button-new:hover {
  background-color: #104f6f;
  transform: scale(1.05);
}

.car-listings-new {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px; /* Reduced gap between cards */
  width: 100%;
}

.car-card-new {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  background-color: #C5EDFF;
  color: #333;
  transition: box-shadow 0.3s ease;
}

.car-card-new:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.car-image-new img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.car-card-new:hover .car-image-new img {
  transform: scale(1.05);
}

.car-header-new {
  padding: 8px; /* Reduced padding */
  font-weight: bold;
}

.car-details-new {
  padding: 8px; /* Reduced padding */
}

.car-details-new table {
  width: 100%;
  border-collapse: collapse;
}

.car-details-new table td {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.car-price-new {
  margin-top: 8px; /* Reduced margin */
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  color: #146C94;
}

.centered-space-new {
  text-align: center;
  margin-top: 15px; /* Reduced margin */
  color: #333;
}

.text-section {
  text-align: center;
}

.logo-banner-home {
  margin-top: 15px; /* Reduced margin */
}

/* Skeleton Screens */
.skeleton-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px; /* Reduced gap */
  width: 100%;
}

.skeleton-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #e0e0e0;
  width: 100%;
  max-width: 300px;
  height: 350px;
  position: relative;
  overflow: hidden;
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background-color: #d8d8d8;
}

.skeleton-text {
  width: 80%;
  height: 20px;
  background-color: #cccccc;
  margin: 10px 0;
  border-radius: 4px;
}

/* Car Image and Card Styling */
.car-image-new {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d8d8d8;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.car-header-new {
  padding: 8px;
  font-weight: bold;
}

.car-details-new {
  padding: 8px;
}

.car-details-new table {
  width: 100%;
  border-collapse: collapse;
}

.car-details-new table td {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.car-price-new {
  margin-top: 8px;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  color: #146C94;
}

/* Loading Animation for Skeleton Screens */
.skeleton-card::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

/* Loading Circle */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.loading-circle {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #146C94;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Adjustments for screens under 570px */
@media (max-width: 570px) {
  .skeleton-container {
    grid-template-columns: 1fr;
  }

  .skeleton-card {
    max-width: 100%;
  }

  .car-listings-new {
    grid-template-columns: 1fr;
  }

  .region-buttons-new {
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: nowrap; /* Keep buttons on the same line for longer */
    margin-top: 1px; /* Significantly reduced margin above the buttons */
  }

  .region-button-new {
    margin: 5px 0;
    width: auto; /* Ensures buttons fit better on mobile */
  }

  .search-section-new {
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
  }

  .search-bar {
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .search-button {
    border-radius: 4px;
  }
}

/* Media Queries for other small screens */
@media (max-width: 300px) {
  .region-buttons-new {
    flex-direction: column;
    width: 100%;
    margin-top: 1px; /* Further reduced margin above the buttons */
  }

  .region-button-new {
    margin: 5px 0;
    width: 100%;
  }
  
  .search-section-new {
    flex-direction: column;
    box-shadow: none;
  }

  .search-bar {
    border-radius: 4px;
  }

  .search-button {
    border-radius: 4px;
  }
}

/* reduce the margin between search section and the region filter buttons */
@media (max-width: 401px) {
  .search-section-new {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .region-buttons-new {
    margin-top: 0px;
  }

  .region-button-new {
    margin: 0px;
  }
}
