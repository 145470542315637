/* General styles */
body {
	background-color: #fff;
  }
  

main {
	padding-bottom: 10vh;
}

.banner {
	background-color: #fff;
	color: #fff;
	text-align: center;
	position: relative;
  }
  
  .options {
	display: flex;
	justify-content: space-evenly;
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 10px 0;
  }
  
  .option-link {
	font-family: 'Antonio', sans-serif;
	color: #146C94;
	text-decoration: none;
	font-size: 2em;
	transition: background-color 0.3s ease;
	border-radius: 8px;
	padding: 10px 20px;
	z-index: 10;
	width: 100%;
  }
  
  .option-link:hover {
	color: #19A7CE;
  }
  
  .image-section {
	width: 100%;
	object-fit: cover;
  }
  
  .full-width-image {
	display: block;
	object-fit: cover;
	width: 100%;
	height: auto;
	min-height: 50vh;
  }
  
  .sell-banner {
	background-color: #146C94;
	text-align: center;
	padding: 20px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	transition: background-color 0.3s ease;
	font-family: 'Antonio', sans-serif;
	font-weight: 400;
	letter-spacing: 1px;
  }
  
  .sell-banner h2 {
	color: #fff;
	font-size: 1em;
	font-weight: 400;
  }
  
  .sell-banner p {
	color: #fff;
	font-size: 1.5em;
  }
  
  .sell-banner .cta-link {
	font-size: 0.75em;
	background-color: transparent;
	color: #fff;
	transition: background-color 0.3s ease;
  }
  
  .sell-banner .cta-link:hover {
	background-color: transparent;
  }
  
  .sell-banner:hover {
	background-color: #19A7CE;
  }
  
  
  .background {
	background-color: #146C94;
  }

  
  .logo-banner-home {
	text-align: center;
	padding: 20px;
  }
  
  .logo-banner {
	text-align: center;
	padding: 20px;
	background-color: #146C94;
	padding-bottom: 5rem;
	margin-bottom: 5vh;
  }
  
  .logo-banner.dark {
	background-color: #181818;
  }
  
  .text-section {
	background-color: #fff;
	color: #333;
	max-width: 800px;
	justify-content: center;
	margin: 0 auto;
	/* margin-bottom: 5vh; */
	text-align: center;
  }
  
  .text-section h3 {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	font-family: 'Antonio', sans-serif;
	letter-spacing: 1px;
	font-size: 1.5em;
	color: #333;
  }
  
  .text-section h2 {
	text-align: center;
	padding: 20px;
	background-color: #146C94;
	font-family: 'Antonio', sans-serif;
	letter-spacing: 1px;
	font-size: 2em;
	color: #fff;
  }
  
  .text-section p {
	text-align: center;
	padding: 20px;
	font-family: 'abel', sans-serif;
	font-size: 1.25em;
	margin-bottom: 0px;
	padding-bottom: 0px;
  }
  
  .car-image {
	width: 100%;
	display: block;
	transition: transform 0.3s ease-in-out;
  }
  
  .car-title {
	margin-top: 8px;
	font-size: 1rem;
	color: #333;
	font-family: 'Antonio', sans-serif;
  }
  
  .see-all-button {
	text-align: center;
	font-family: 'Antonio', sans-serif;
	font-size: 1.25em;
	padding-top: 15px;
  }
  
  .see-all-link {
	display: inline-block;
	padding: 10px 20%;
	color: #fff;
	text-decoration: none;
	border-radius: 8px;
	transition: background-color 0.3s ease-in-out;
	background-color: #146C94;
  }
  
  .see-all-link:hover {
	background-color: #19A7CE;
  }
  
  .brand-section {
	font-family: 'abel', sans-serif;
	/* max-width: 800px; */
	justify-content: center;
	/* margin: 0 auto; */
	margin-top: 5vh;
	margin-bottom: 5vh;
  }
  
  .search-section {
	position: relative;
	display: flex;
	margin: 0 auto;
	max-width: 600px;
	flex-wrap: wrap;
	color: #333;
	font-family: 'abel', sans-serif;
  }


  .margin-top {
	margin-top: 2vh;
  }
  
  .search-bar {
	flex: 1;
	height: 40px;
	padding: 0 10px;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 8px 0 0 8px;
	box-sizing: border-box;
	outline: none;
  }
  
  .search-button {
	height: 40px;
	padding: 0 20px;
	font-size: 16px;
	cursor: pointer;
	background-color: #146C94;
	color: white;
	border: none;
	border-radius: 0 8px 8px 0;
  }
  
  .search-button:hover {
	background-color: #19A7CE;
  }
  
  .suggestions-list {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: white;
	border: 1px solid #ddd;
	list-style-type: none;
	margin: 0;
	padding: 0;
	z-index: 1000;
  }
  
  .suggestions-list li {
	padding: 10px;
	cursor: pointer;
  }
  
  .suggestions-list li:hover {
	background-color: #f0f0f0;
  }
  
  .white-background {
	background-color: #fff;
	color: #333;
  }
  
  .image-section {
	position: relative;
	width: 100%;
  }
  
  .padding {
	padding: 50px;
	background-color: #146C94;
  }
  
  .content {
	background-color: white;
	width: 100%;
	position: relative;
	margin-top: 40vh;
  }
  
  .img-container {
	position: relative;
	width: 100%;
	overflow: hidden;
	top: 0;
	left: 0;
	position: fixed;
	z-index: -1;
  }
  
  .img-container img {
	width: 100%;
	object-fit: cover;
  }
  
  .divider {
	width: 5px;
	background-color: #146C94;
	border-radius: 5px;
  }
  
  .vertical-divider {
	height: 5vh;
  }
  
  .brand-divider {
	background-color: #444;
	height: 2px;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
  }
  
  .centered-big-title {
	text-align: center;
	font-family: 'Antonio', sans-serif;
	font-size: 12px;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #fff;
	background-color: #146C94;
  }
  
  .centered-small-title {
	text-align: center;
	font-family: 'Antonio', sans-serif;
	font-size: 1em;
	padding: 20px;
	color: #444;
  }
  
  .brand-text {
	font-size: 1.25em;
  }
  
  /* Media Queries */
@media (max-width: 400px) {
	.search-section {
		flex-direction: column;
		align-items: stretch;
		min-height: 110px;
	}

	.search-bar, .search-button {
	  width: 100%;
	  margin-bottom: 10px;
	}
  
	.search-bar {
	  border-radius: 8px;
	}
  
	.search-button {
	  border-radius: 8px;
	  height: 50px;
	}
}
  @media (max-width: 600px) {
	.text-section {
	  font-size: 0.75em;
	}
  
	.logo-svg-home {
	  width: 100%;
	  height: auto;
	  padding-top: 20px;
	  max-width: 200px;
	}
  

  
	.options {
	  padding-top: 13px;
	  bottom: 0;
	  width: 100%;
	}
  
	.brand-section .row {
	  position: relative;
	  display: flex;
	  min-height: 50vh;
	  justify-content: flex-start;
	  align-items: center;
	  overflow: hidden;
	  background-color: #fff;
	  font-size: 0.75em;
	}
  
	.brand-section .row.reverse {
	  flex-direction: row-reverse;
	}
  
	.brand-section .text-container {
	  flex: 1;
	  padding: 20px;
	  display: flex;
	  justify-content: center;
	  text-align: center;
	}
  
	.brand-section .brand-image {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  transform: translateX(-50%);
	}
  
	.brand-section .row.reverse .brand-image {
	  transform: translateX(50%);
	}
  
	.brand-section .image-container {
	  width: 50%;
	  height: 100%;
	  overflow: hidden;
	}
  
	.brand-section .brand-text {
	  text-align: center;
	  margin: 0 auto;
	}

  }
  
  @media (min-width: 600px) {
	
  
	.brand-section .row {
	  position: relative;
	  display: flex;
	  min-height: 100vh;
	  justify-content: center;
	  align-items: center;
	  overflow: hidden;
	  font-size: 0.9em;
	}
  
	.brand-section .row.reverse {
	  flex-direction: row-reverse;
	}
  
	.brand-section .text-container {
	  flex: 1;
	  padding: 20px;
	  display: flex;
	  justify-content: center;
	  text-align: center;
	}
  
	.brand-section .brand-image {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  transform: translateX(-50%);
	}
  
	.brand-section .row.reverse .brand-image {
	  transform: translateX(50%);
	}
  
	.brand-section .image-container {
	  width: 50%;
	  height: 100%;
	  overflow: hidden;
	}
  
	.brand-section .brand-text {
	  text-align: center;
	  margin: 0 auto;
	}
  
	.full-width-image {
	  min-height: 85vh;
	  margin-top: 0vh;
	}
  
	.content {
	  margin-top: 70vh;
	}
  }
  
  @media (min-width: 800px) {
	.full-width-image {
	  min-height: 80vh;
	  margin-top: 0vh;
	}
  
	.content {
	  margin-top: 70vh;
	}
  }
  
  @media (min-width: 1001px) {
	.brand-text {
	  font-size: 1.25em;
	}
  
	.brand-section .brand-image {
	  transform: translateX(-50%);
	}
  
	.brand-section .row.reverse .brand-image {
	  transform: translateX(50%);
	}
  
	.brand-section .image-container {
	  width: 50%;
	  height: 70%;
	  min-height: 200vh;
	  overflow: hidden;
	}
  
	.full-width-image {
	  min-height: 80vh;
	  margin-top: 0vh;
	}
  
	.content {
	  margin-top: 70vh;
	}

	.text-container {
		font-size: 1.5em;
	}

	.text-section p {
		font-size: 1.5em;
	}
	.text-section h3 {
		font-size: 1.75em;
	}
  }
  
  @media (min-width: 1501px) {
	.full-width-image {
	  min-height: 80vh;
	  margin-top: -20vh;
	}
  }

  @media (max-width: 400px) {
	.brand-section .brand-image {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  font-size: 0.5em;
	  transform: translateX(-50%);
	}
  
	.brand-section .row.reverse .brand-image {
	  transform: translateX(50%);
	}
  
	.brand-section .image-container {
	  width: 50%;
	  height: 100%;
	  overflow: hidden;
	}
  }
  
  @media (max-width: 335px) {
	.brand-section .row {
	  font-size: 0.7em;
	}
  }
  
  @media (max-width: 315px) {
	.brand-section .row {
	  font-size: 0.7em;
	}
  }
  
  @media (max-width: 300px) {
	.brand-section .row {
	  font-size: 0.6em;
	}
  }
  
  @media (max-width: 250px) {
	.brand-section .row {
	  font-size: 0.5em;
	}
  }

@media (max-width: 600px) {
	.search-section {
		margin-left: 5px;
		margin-right: 5px;
	}
}
/* 
@media (min-width: 950px) {
	.full-width-image {
	  min-height: 100%;
	  margin-top: -30vh;
	}
  
	.content {
	  margin-top: 80vh;
	}
} */

