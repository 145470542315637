
.sell-car-page {
    background-color: #FFFFFF;
    font-family: 'Abel', sans-serif;
    font-weight: 400;
    margin-top: 5vh;
}

.sell-title {
    color: #333;
    text-align: center;
    font-size: 2rem;
}

.sell-car-form {
    max-width: 600px;
    margin: 0 auto;
    color: #333;
    margin-bottom: 20px;
    padding: 20px;
}

.centered {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
}

.photoInput {
    width: 100%;
    
}

.privacy-hyperlink {
    color: #19A7CE;
    text-decoration: none;
    padding-left: 10px;
    font-weight: semi-bold;
}

.sell-car-form h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
}

.sell-car-form form {
    display: flex;
    flex-wrap: wrap;
}

.sell-car-form label {
    width: 100%;
    margin-bottom: 10px;
}

.sell-car-form input[type="text"],
.sell-car-form input[type="number"],
.sell-car-form input[type="tel"],
.sell-car-form input[type="email"],
.sell-car-form select,
.sell-car-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    /* margin-bottom: 1px; */
}

.sell-car-form textarea {
    resize: vertical;
    min-height: 100px;
}

.sell-car-form input[type="checkbox"] {
    margin-right: 10px;
    margin-left: 10px;
    vertical-align: middle;
}

.sell-car-form button {
    background-color: #19A7CE;
    color: #fff;
    border: none;
    padding: 20px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1rem;
    width: 100%;
    transition: background-color 0.3s;
}

.submit:hover {
    background-color: #146C94;
}

.sell-car-form button:hover {
    background-color: #146C94;
}

.success-banner {
    position: fixed;
    top: 64;
    width: 100%;
    /* height: 50px; */
    /* transform: translateX(-50%); */
    background-color: #4CAF50;
    color: white;
    padding: 15px 20px;
  }
  
  .success-banner.show {
    display: block;
  }
  
.sell-car-form h3 {
    padding-top: 20px;
    padding-bottom: 20px;
}

/* add padding left and right for type file */
.sell-car-form input[type="file"] {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.sell-car-type {
    padding-top: 20px;
    padding-bottom: 20px;
}

.sell-car-title {
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
}

.special-label {
    display: flex;
    align-items: center; /* Align items vertically center */
    margin-bottom: 10px; /* Add some spacing below each label */
  }

  .special-label input {
    margin-left: 10px; /* Add some spacing to the left of the input */
}
  
/* Style for the checkbox input */
input[type="checkbox"] {
margin-right: 8px; /* Space between checkbox and text */
}

/* Optional: Add spacing between the labels for visual clarity */
label + label {
margin-top: 10px; /* Add spacing between labels */
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
}

.navigation-buttons button {
    flex: 1;
    /* padding: 10px; */
    /* margin: 5px; */
    width: 50%;
}

.navigation-buttons button:hover {
    background-color: #146C94;
    color: #fff;
}


.navigation-buttons button:nth-child(2) {
    margin-left: 10px;
}

@media (max-width: 600px) {
    .sell-car-form {
        padding: 15px;
    }
}


.section-indicator {
    /* center horizontal; */
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .section-indicator .dot {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .section-indicator .dot.active {
    background-color: #4CAF50; /* Active dot color */
  }
  
  .error {
    color: red;
    font-size: 0.875em;
  }
  