
.submission-title {
    font-family: 'Antonio', sans-serif;
    color: #333;
    text-align: center;
    padding: 10px;
    font-size: 2rem;
}

.submission-text {
    font-family: 'Abel', sans-serif;
    color: #333;
    text-align: center;
    padding: 10px;
    font-size: 1.5rem;
}

.buttons {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    /* stack */
    flex-direction: column;
    align-items: center;
}

.button {
    background-color: #146C94;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

.button:focus {
    outline: none;
}

@media (min-width: 1001px) {
    .submission-section {
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 50px;
    }
}