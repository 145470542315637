.dashboard-container {
  padding: 20px;
  color: #333;
  font-family: 'abel', sans-serif;
}

.dashboard-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filter-buttons button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #19A7CE;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-buttons button.active,
.filter-buttons button:hover {
  background-color: #19A7CE;
}

.dashboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.dashboard-table th,
.dashboard-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.dashboard-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.dashboard-table tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.dashboard-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #19A7CE;
  color: white;
}

.dialog-content p {
  margin: 10px 0;
}

.dialog-content img {
  margin: 10px 0;
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.dialog-content {
  color: #333;
  font-family: 'abel', sans-serif;
}

.dialog-content strong {
  font-weight: bold;
}

.dialog-content .MuiTextField-root {
  margin-bottom: 16px;
}

.dialog-actions {
  display: flex;
  justify-content: space-between;
}

.dialog-actions button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog-actions button:hover {
  background-color: #ddd;
}

.dialog-actions .MuiButtonBase-root {
  margin-left: 10px;
  margin-right: 10px;
}

.dialog-actions .MuiButtonBase-root:last-child {
  margin-right: 0;
}

.dialog-content {
  min-width: 300px;
  color: #333;
  font-family: 'abel', sans-serif;
}

.image-preview {
  position: relative;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}



@media (min-width: 600px) {
  .dialog-content {
    max-width: 600px;
  }
}

@media (max-width: 600px) {
  .dashboard-container {
    padding: 10px;
  }

  .dashboard-table th,
  .dashboard-table td {
    font-size: 12px;
    padding: 6px;
  }

  .filter-buttons button {
    padding: 8px 15px;
    margin: 3px;
    font-size: 14px;
  }

  .dialog-content {
    padding: 10px;
  }

  .dialog-content p {
    font-size: 14px;
  }

  .dialog-content .MuiTextField-root {
    margin-bottom: 12px;
  }

  .dialog-actions button {
    padding: 8px 15px;
    font-size: 14px;
  }
}

.cancel-button {
  color: red !important;
}

.cancel-button:hover {
  background-color: darkred;
}
