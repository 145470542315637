
.meet-team-section {
    background-color: #fff;
    text-align: start;
    color: #000;
    font-weight: 300;
    font-family: 'Abel', sans-serif;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 5vh;
}

.meet-team-section .reverse {
    text-align: end;
}

.meet-team-title {
    font-family: 'Antonio', sans-serif;
    color: #333;
    text-align: center;
    padding: 10px;
    font-size: 2rem;
    font-weight: 400;
}

.meet-team-section h2 {
    /* text-align: center; */
    font-weight: 400;
    background-color: #fff;
    padding: 20px;
    font-family: 'Antonio', sans-serif;
    letter-spacing: 1px;
    text-align: center;
}

.meet-team-section h3 {
    letter-spacing: 1px;
    background-color: #fff;
    font-weight: 400;
    font-size: 1.5em;
    /* text-align: center; */
    padding: 20px;
    font-family: 'Abel', sans-serif;
    text-align: center;
}

.row {
    display: flex;
    overflow: hidden;
    justify-content: start;
    align-items: flex-start;
    max-height: 75vh;
    overflow: hidden;
}

.row.reverse {
    flex-direction: row-reverse;
    text-align: flex-end;
}

.team-image {
    width: 40%;
    max-width: 100%;
    height: auto;
}

.team-text {
    flex: 1;
    color: #000;
    padding: 20px;
}

.team-text h4 {
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 1em;
}

.team-text h5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.team-text p {
    margin-bottom: 15px;
}